<template>
  <div id="user-profile">
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <FormInputGroup
              v-model="userModel.username"
              :label="$t('forms.labels.username')"
            />
          </b-col>
          <b-col md="6">
            <FormInputGroup
              v-model="userModel.firstName"
              :label="$t('forms.labels.firstName')"
              mask="Aa+"
            />
          </b-col>
          <b-col md="6">
            <FormInputGroup
              v-model="userModel.lastName"
              :label="$t('forms.labels.lastName')"
              mask="Aa+"
            />
          </b-col>
          <b-col md="6">
            <FormInputGroup
              v-model="userModel.email"
              :label="$t('forms.labels.email')"
              disabled="disabled"
            />
          </b-col>
          <b-col md="6">
            <FormInputGroup
              v-model="userModel.phone"
              :label="$t('forms.labels.phone')"
              mask="!+7 (###) ###-##-##"
            />
          </b-col>
          <b-col md="6">
            <div class="d-flex align-items-end gap-05">
              <FormFileGroup
                :value.sync="userModel.avatar"
                :label="$t('forms.labels.avatar')"
                sxGroup="mb-0 w-100"
              />
              <b-avatar
                :src="userModel.avatar ? avatarUrl : null"
                size="40"
                variant="light-primary"
                class="badge-minimal"
                badge-variant="success"
                badge
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col md="12">
        <FormInputGroup
          :value="`${localUrl}/sign_up/?invite=${userModel.groupId}`"
          :label="$t('forms.labels.inviteLink')"
          :disabled="true"
        >
          <template #append>
            <b-button v-ripple variant="primary" @click="onCopy">
              <feather-icon icon="CopyIcon" />
            </b-button>
          </template>
        </FormInputGroup>
      </b-col>
    </b-row>

    <ActionButtons
      :isEdit="true"
      @save="saveHandler"
      @saveStay="saveHandler(true)"
    />
  </div>
</template>

<script>
import { reactive, computed, ref, watch, onMounted } from '@vue/composition-api'
import { useUsersQuery } from '@/store/users'
import { BRow, BCol, BButton, BAvatar, BCard } from 'bootstrap-vue'
import {
  FormInputGroup,
  FormFileGroup,
  FormSelectGroup,
} from '@/views/components/forms'
import store from '@/store'
import useToast from '@/views/components/useToast'
import ActionButtons from '@/views/components/pages/ActionButtons.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getImage } from '@/api/attachments'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAvatar,
    BCard,
    FormInputGroup,
    FormFileGroup,
    FormSelectGroup,
    ActionButtons,
  },
  setup() {
    const { t } = useI18nUtils()
    const toast = useToast()
    const localUrl = window.location.origin
    const isStay = ref(false)
    const users = computed(() => store.getters['usersStore/list'])

    const toastsText = {
      edit: t('notifications.success.users.edit'),
      invite: t('notifications.success.users.invite'),
    }

    let userModel = reactive({
      email: '',
      username: '',
      avatar: '',
      groupId: null,
      locale: '',
      roles: [],
      role: 'guest',
    })

    userModel = Object.assign(userModel, store.getters['usersStore/user'])

    const avatarUrl = ref('')

    watch(userModel, async () => {
      if (userModel.avatar) {
        avatarUrl.value = await getImage({ filename: userModel.avatar })
      }
    })

    onMounted(async () => {
      if (userModel.avatar) {
        avatarUrl.value = await getImage({ filename: userModel.avatar })
      }
    })

    const { mutate: update } = useUsersQuery.update({
      user: userModel,
      options: {
        onSuccess(data) {
          if (data.success) {
            store.dispatch('usersStore/set', data.user)
            if (!isStay.value) {
              router.go(-1)
            }
            toast.success(toastsText.edit)
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const saveHandler = isStayHere => {
      isStay.value = !!isStayHere
      update()
    }

    const onCopy = () => {
      navigator.clipboard.writeText(
        `${localUrl}/sign_up/?invite=${userModel.groupId}`
      )
      toast.success(toastsText.invite)
    }

    return {
      userModel,
      users,
      localUrl,
      onCopy,
      saveHandler,
      avatarUrl,
    }
  },
}
</script>
